import Vue from 'vue'
import VueRouter from 'vue-router'
import main from '@/main'
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}
// Routes
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'device-deviceList' } },
    {
      path: '/device',
      name: 'device',
      component: () => import('@/views/device/devicePage.vue'),
      children: [
        {
          path: '/device/deviceList',
          name: 'device-deviceList',
          component: () => import('@/views/device/deviceList.vue'),
        },
        {
          path: '/device/deviceInfo',
          name: 'device-deviceInfo',
          component: () => import('@/views/device/deviceInfo.vue'),
        }
      ],
    },
    {
      path: '/event',
      name: 'event',
      component: () => import('@/views/event/eventPage.vue'),
      children: [
        {
          path: '/event/recordList',
          name: 'event-recordList',
          component: () => import('@/views/event/record/recordList.vue'),
        },
        {
          path: '/event/recordInfo',
          name: 'event-recordInfo',
          component: () => import('@/views/event/record/recordInfo.vue'),
        },
        {
          path: '/event/reportList',
          name: 'event-reportList',
          component: () => import('@/views/event/report/reportList.vue'),
        },
        {
          path: '/event/reportInfo',
          name: 'event-reportInfo',
          component: () => import('@/views/event/report/reportInfo.vue'),
        },
      ],
    },
    {
      path: '/config',
      name: 'config',
      component: () => import('@/views/config/configPage.vue'),
      children: [
        {
          path: '/config/tenant',
          name: 'tenant-config',
          component: () => import('@/views/config/tenantConfig.vue'),
        },
        {
          path: '/config/vms',
          name: 'vms-config',
          component: () => import('@/views/config/vmsConfig.vue'),
        },
        {
          path: '/config/sms',
          name: 'sms-config',
          component: () => import('@/views/config/smsConfig.vue'),
        },
      ],
    },
    {
      path: '/log',
      name: 'log',
      component: () => import('@/views/log/logPage.vue'),
      children: [
        {
          path: '/log/record',
          name: 'log-record',
          component: () => import('@/views/log/logRecord.vue'),
        },
      ],
    },
    {
      path: '/auth',
      name: 'auth',
      component: () => import('@/views/auth/authPage.vue'),
      children: [
        {
          path: '/auth/roleList',
          name: 'auth-roleList',
          component: () => import('@/views/auth/role/roleList.vue'),
        },
        {
          path: '/auth/roleInfo',
          name: 'auth-roleInfo',
          component: () => import('@/views/auth/role/roleInfo.vue'),
        },
        {
          path: '/auth/accountList',
          name: 'auth-accountList',
          component: () => import('@/views/auth/account/accountList.vue'),
        },
        {
          path: '/auth/accountInfo',
          name: 'auth-accountInfo',
          component: () => import('@/views/auth/account/accountInfo.vue'),
        },
      ],
    },
    {
      path: '/label',
      name: 'label',
      component: () => import('@/views/label/labelPage.vue'),
      children: [
        {
          path: '/label/configList',
          name: 'label-configList',
          component: () => import('@/views/label/config/configList.vue'),
        },
        {
          path: '/label/configInfo/:id/:edit',
          name: 'label-configInfo',
          component: () => import('@/views/label/config/configInfo.vue'),
        },
        {
          path: '/label/relationDeviceList',
          name: 'label-relationDeviceList',
          component: () => import('@/views/label/relationDevice/relationDeviceList.vue'),
        },
      ],
    },
    {
      path: '/person',
      name: 'person',
      component: () => import('@/views/person/personPage.vue'),
      children: [
        {
          path: '/person/personList',
          name: 'person-personList',
          component: () => import('@/views/person/personList.vue'),
        },
        {
          path: '/person/personInfo',
          name: 'person-personInfo',
          component: () => import('@/views/person/personInfo.vue'),
        },
      ],
    },
    {
      path: '/sleep',
      name: 'sleep',
      component: () => import('@/views/sleep/sleepPage.vue'),
      children: [
        {
          path: '/sleep/sleepReportList',
          name: 'sleep-sleepReportList',
          component: () => import('@/views/sleep/sleepReportList.vue'),
        },
        {
          path: '/sleep/sleepReportInfo',
          name: 'sleep-sleepReportInfo',
          component: () => import('@/views/sleep/sleepReportInfo.vue'),
        },
      ],
    },
    {
      path: '/resource',
      name: 'resource',
      component: () => import('@/views/resource/resourcePage.vue'),
      children: [
        {
          path: '/resource/resourceList',
          name: 'resource-resourceList',
          component: () => import('@/views/resource/resourceList.vue'),
        },
        {
          path: '/resource/resourceInfo',
          name: 'resource-resourceInfo',
          component: () => import('@/views/resource/resourceInfo.vue'),
        },
      ],
    },
    {
      path: '/homemonitor/homeMonitor',
      name: 'homemonitor-homeMonitor',
      component: () => import('@/views/homemonitor/homeMonitor.vue'),
    },
    {
      path: '/custody',
      name: 'custody',
      component: () => import('@/views/custody/custodyPage.vue'),
      children: [
        {
          path: '/custody/ruleList',
          name: 'custody-ruleList',
          component: () => import('@/views/custody/ruleList.vue'),
        },
        {
          path: '/custody/ruleInfo',
          name: 'custody-ruleInfo',
          component: () => import('@/views/custody/ruleInfo.vue'),
        },
      ],
    },
    {
      path: '/dashboard/dashboardList',
      name: 'dashboard-dashboardList',
      component: () => import('@/views/dashboard/dashboardList.vue'),
    },
    {
      path: '/dashboard/personDashboardList',
      name: 'dashboard-personDashboardList',
      component: () => import('@/views/dashboard/personDashboardList.vue'),
    },
    {
      path: '/dashboard/dashboardDeviceList',
      name: 'dashboard-deviceList',
      // eslint-disable-next-line import/extensions
      component: () => import('../views/dashboard/dashboardDeviceList'),
    },
    {
      path: '/group',
      name: 'group',
      component: () => import('@/views/group/groupPage.vue'),
      children: [
        {
          path: '/group/config',
          name: 'group-config',
          component: () => import('@/views/group/config.vue'),
        },
        {
          path: '/group/setting',
          name: 'group-setting',
          component: () => import('@/views/group/setting.vue'),
        },
      ],
    },
    {
      path: '/login',
      name: 'auth-login',
      component: () => import('@/views/pages/authentication/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  console.log('router to',to)
  if (to.meta.layout == 'full' && to.name != 'auth-login') {
      return next()
  }
  const isLoggedIn = isUserLoggedIn()
  let userData = null
  if (isLoggedIn)
      userData = getUserData()
  else if (to.name == 'auth-login') {
      return next()
  }else{
    return next({
      path: "/login"
    })
  }
  if (isLoggedIn && to.name == 'auth-login') {
      return next(getHomeRouteForLoggedInUser(userData))
  }
  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
      return next(getHomeRouteForLoggedInUser(userData))
  }
  // 路由拦截
  if(userData.routeList.indexOf(to.name) < 0){
    main.app.$bvToast.toast("暂无权限访问", {
      title: '提醒',
      autoHideDelay: 3000,
      appendToast: true,
      variant: 'danger',
    })
    return
  }
  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
