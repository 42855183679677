import useJwt from '@/auth/jwt/useJwt'
/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  return localStorage.getItem('userData') && localStorage.getItem('Authorization')
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

export const checkButtonPermission = (willCheckPermission) => {
  let userDataStr = localStorage.getItem('userData')
  if (!userDataStr)
    return false
  let userData = JSON.parse(userDataStr)
  let interfacePermissionList = userData.interfacePermissionList
  return interfacePermissionList.indexOf(willCheckPermission) > -1;
}
/**
 * 登录完成后要跳转到的页面
 * 跳转到页面 能访问
 */
export const getHomeRouteForLoggedInUser = userData => {
  console.log('userData',userData)
  if(userData.urlPermissionTree[0].children && userData.urlPermissionTree[0].children.length > 0){
     return { name: userData.urlPermissionTree[0].children[0].route}
  }
    return { name: userData.urlPermissionTree[0].route}
}
