import axios from 'axios'
import main from '@/main'

const message = {
    error(msg) {
        main.app.$bvToast.toast(msg, {
            title: 'Error',
            autoHideDelay: 3000,
            appendToast: true,
            variant: 'danger',
          })
    },
}

axios.defaults.withCredentials = true
axios.defaults.crossDomain = true
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

// 根据 VUE_APP_BASE_URL 生成 baseUrl
let baseUrl = process.env.VUE_APP_BASE_URL  // 开发环境
if (!baseUrl.match(/^https?/i)) {
  baseUrl = `${window.location.protocol}//${window.location.host}${process.env.VUE_APP_BASE_URL}`
}

const request = (method, path, params, data, others = {}) => {
  const url = baseUrl + path
  let headers;
  headers = {
    'Authorization': window.localStorage.getItem('Authorization')
  }
  if(others && others.others == 'import'){
    headers = {
      'Authorization': window.localStorage.getItem('Authorization'),
      'Content-Type': 'multipart/form-data'
    }
  }
  return axios({
    method,
    url,
    params,
    data,
    headers,
    ...others
  }).then((response) => {
    if (response.data.success) {
      return Promise.resolve(response)
    }
    return Promise.reject(response)
  }).catch((e) => {
    let response = e.data
    if (!response) {
      return e
    }
    if (response.code === 400) {
      message.error(response.msg)
    }
    if (response.code === 401) {
      message.error(response.msg)
      setTimeout(()=>{
        window.localStorage.removeItem('Authorization')
        window.localStorage.removeItem('username')
        window.localStorage.removeItem('userData')
        window.location.href="/"
      },2000)
    }
    if (response.code === 500 ) {
      message.error(response.msg)
    }
    return e
  })
}

const get = (path, params,others) => {
  return request('GET', path, params, null,others)
}

const post = (path, data,others) => {
  return request('POST', path, {}, data, others)
}

const put = (path, data) => {
  return request('PATCH', path, {}, data, null)
}

const del = (path, data) => {
  return request('DELETE', path, {}, data, null)
}

export default {
  baseUrl,
  request,
  get,
  post,
  put,
  delete: del
}