import request from "@/api/request";

let userData = localStorage.getItem("userData")
if (userData) {
    try {
        userData = JSON.parse(userData)
    }
    catch (e) {
        userData = {}
    }
}
else {
    userData = {}
}

export default {
  namespaced: true,
  state: {
    userData: userData,
  },
  mutations: {
    SET_USERDATA: (state, payload) => {
      state.userData = payload;
      if (payload)
        localStorage.setItem("userData", JSON.stringify(payload));
    },
  },
  actions: {
    getUserData({ commit }) {
      if (localStorage.getItem("Authorization")) {
        commit("SET_USERDATA", userData);
      }
    },
    setUserData({ commit }, val) {
        commit("SET_USERDATA", val);
    },
    removeUserData({ commit }) {
        commit("SET_USERDATA", {});
        localStorage.removeItem('userData')
      },
  },
};
