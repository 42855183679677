<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <div>
      <!-- toast -->
      <div id="example-toast" style="display:block;">
        <div class="dash-msg" :key="index" v-for="(item,index) in websocketNews">
          <div class="dash-title mb-1">{{item.title}}</div>
          <div class="dash-txt mb-1">{{item.content}}</div>
          <div class="dash-time mb-1">{{timestampToDateformat(item.time)}}</div>
          <div class="dash-btn" style="cursor:pointer" @click="removeWebsocketNewItem(index,item)">请尽快查看核实</div>
        </div>
      </div>
    </div>
    <component :is="layout">
      <router-view />
    </component>

    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'
import { getUserData } from '@/auth/utils'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  data() {
    return {
      websocketNews:[],
      websock: null, //建立的连接
      lockReconnect: false, //是否真正建立连接
      timeout: 20 * 1000, //20秒一次心跳
      timeoutnum: null, //断开 重连倒计时
      heartbeatIntervalFunc: null,
      cyclePlayVoiceIntervalFunc: null, // 循环播放声源的定时方法
      voicePlayingLock: false,
      whilePlayVoiceFlag: false,
      speechSynthesis: window.speechSynthesis,
    }
  },
  watch:{
    websocketNews: function(newNews){
      console.log('newNews',newNews)
      if(newNews.length <= 0){
        this.whilePlayVoiceFlag = false
        return
      }
      // 继续循环播放
      this.whilePlayVoiceFlag = true

    },
    whilePlayVoiceFlag: function(newValue){
      console.log('newValue',newValue)
      if(newValue){
          // 循环播放
          this.cyclePlayVoice()
          return
      }
      clearInterval(this.cyclePlayVoiceIntervalFunc)
    }
  },
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    ScrollToTop,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      enableScrollToTop,
    }
  },
  mounted() {
      // 开启心跳
      this.heartbeat()
  },
  created() {
    // 页面刚进入时开启长连接
    this.initWebSocket()
  },
  destroyed() {
    //页面销毁时关闭长连接
    this.websock.close()
    clearInterval(this.heartbeatIntervalFunc)
  },
  methods: {
    cyclePlayVoice(){
      let that = this
      this.cyclePlayVoiceIntervalFunc = setInterval(() => {
        // console.log('正在循环播放',that.voicePlayingLock)
        if(!that.voicePlayingLock){// 锁
          console.log('获得播放锁,正在播放')
          that.voicePlayingLock = true
          that.speck("当前有警报待处理，请处理!")
        }
      },2000)
    },
    speck(word){
      let that = this
      let msg = new window.SpeechSynthesisUtterance();
      msg.text = word //播放文案
      msg.volume = '1' // 声音的音量，区间范围是0到1，默认是1。
      msg.rate = 1 // 设置播放语速，范围：0.1 - 10之间    正常为1倍播放
      msg.pitch = '1' // 表示说话的音高，数值，范围从0（最小）到2（最大）。默认值为1。
      msg.lang = 'zh-cn' // 使用的语言，字符串， 例如："zh-cn"
      msg.onend = (event) => {  //语音合成结束时候的回调（语音读完后触发）
        // console.log('声音播放完毕')
        setTimeout(()=>{
          that.voicePlayingLock = false
        },2000)
      }
      msg.onstart = (event) => {
        // console.log('合成开始')
      }
      msg.onerror = (event) => {
        // console.log('合成异常')
        setTimeout(()=>{
          that.voicePlayingLock = false
        },2000)
      }
      msg.onpause = (event) => {
        // console.log('暂停')
      }
      msg.onresume = (event) => {
        // console.log('恢复')
      }
      msg.onboundary = (event) => {
        // console.log('在单词或句子边界')
      }
      this.speechSynthesis.speak(msg)
    },
    timestampToDateformat(time){
      return new Date(parseInt(time)).toLocaleString().replace(/:\d{1,2}$/,' ');
    },
    removeWebsocketNewItem(index,item){
      this.websocketNews.splice(index, 1)
      // todo 跳转等页面
      const eventLv = item.params.eventLv + 0
      const recordId =item.params.recordId
      console.log('事件ID', recordId)

      if (eventLv === 3) {// 通知事件
        this.$router.push({ name: 'event-recordInfo', query: {id: recordId} })
      }else {// 警报事件
        this.$router.push({ name: 'event-reportInfo', query: {id: recordId} })
      }
    },
    initWebSocket(){
      console.log("initWebSocket")
      let VUE_APP_WEBSOCKET_URL = process.env.VUE_APP_WEBSOCKET_URL
      const userInfo = getUserData()
      if(userInfo && userInfo.token){
        // 实例化socket
        this.websock = new WebSocket(`${VUE_APP_WEBSOCKET_URL}/${userInfo.token}`)
        // 监听socket连接
        this.websock.onopen = this.webSocketOpen
        // 监听socket错误信息
        this.websock.onerror = this.webSocketError
        // 监听socket消息
        this.websock.onmessage = this.webSocketOnMessage
        // 监听socket断开连接的消息
        this.websock.onclose=this.webSocketClose
      }
    },
    heartbeat() {
      //开启心跳
      var self = this
      this.heartbeatIntervalFunc = setInterval(function() {
          console.log('heartbeat')
          const userInfo = getUserData()
          if(!userInfo || !userInfo.token){
            console.log('未检测到登录信息，停止此次心跳')
            // 退出登录 关闭心跳
            if(self.websock)
              self.websock.close()
            return
          }
          //这里发送一个心跳，后端收到后，返回一个心跳消息
          if (self.websock && self.websock.readyState == 1) {
            console.log("向服务器发送心跳","heartbeat")
            //如果连接正常
            self.websock.send("heartbeat");
          } else {
            //否则重连
            self.reconnect()
          }
        }, self.timeout)
    },
    webSocketOpen(){
      console.log("已连接Websocket服务器")
    },
    webSocketError(err){
      console.log("WebSocket连接发生错误")
    },
    webSocketClose(){
      //连接关闭事件
      console.log("连接已关闭");
    },
    webSocketOnMessage(msg){
      if(msg && msg.data){
        let message = JSON.parse(msg.data)
        // let message = {
        //   'title':'警报',
        //   'content': '地点:成都办公室',
        //   'time': '1663742066000',
        //   'params':{
        //     'recordId': '1564550081094930433'
        //   }
        // }
        // 设置声音信息
        if (!message) {
          console.log("消息解析异常",msg.data)
          return
        }
        if(this.websocketNews.length > 4){
          this.websocketNews.shift()
        }
        this.websocketNews.push(message)
      }
    },
    reconnect() {
      console.log('reconnect')
      //重新连接
      var that = this;
      if (that.lockReconnect) {
        console.log("正在重连，无需重复连接")
        return;
      }
      that.lockReconnect = true;
      //没连接上会一直重连，设置延迟避免请求过多
      that.timeoutnum && clearTimeout(that.timeoutnum);
      that.timeoutnum = setTimeout(function() {
        //新连接
        that.initWebSocket()
        that.lockReconnect = false
      }, 5000);
    },
  }
}
</script>


<style lang="scss">
.tLeft{
  text-align: left;
}
.tRight{
  text-align: right;
}
.tCenter{
  text-align: center;
}
[dir] .el-checkbox__input.is-checked .el-checkbox__inner, [dir] .el-checkbox__input.is-indeterminate .el-checkbox__inner{
  background-color: #7367f0 !important;
  border-color: #7367f0 !important;
}
.el-tree-node__label{
  font-size: 16px !important;
}
#app {
  position: relative;
}

#example-toast {
    display: block;
    width: 300px;
    position: absolute;
    top: 0;
    z-index: 20000;
    right: 0px;
}
#example-toast .toast-header {
  background-color: #FFF1F1 !important;
}

#example-toast .toast-body {
  background-color: #FFF1F1 !important;
}

#example-toast .dash-msg {
  background: wheat;
  text-align: center;
  margin-bottom: 10px;

  .dash-title {
    color: #FF4949;
    font-size: 22px;
    font-weight: bold;
  }

  .dash-txt {
    color: #333333;
    font-size: 20px;
  }

  .dash-time {
    color: #666666;
    font-size: 16px;
  }

  .dash-btn {
    width: 150px;
    height: 36px;
    line-height: 36px;
    background: #F0C71D;
    border-radius: 4px;
    color: #fff;
    margin: 0 auto;
    font-size: 18px;
  }
}
</style>
